<template>
  <div class="grid grid-cols-2 gap-4">
    <div class="el-form-validation-margin mt-2">
      <label class="required form-label" for="name">{{
        $t('product.subscribeToEventUpdatesModal.firstStep.form.name.label')
      }}</label>
      <div class="position-relative">
        <input
          id="name"
          ref="firstName"
          v-model="form.name"
          :placeholder="
            $t(
              'product.subscribeToEventUpdatesModal.firstStep.form.name.placeholder'
            )
          "
          autocomplete="name"
          class="w-full border border-gray-200 rounded-md p-2 bg-white mt-1"
          name="name"
          @input="$emit('clearErrors', 'name')"
        />

        <!-- <i
        class="bi bi-person-fill position-absolute top-50 start-0 ms-3 fs-4 translate-middle-y text-black-50"
      ></i> -->
      </div>
      <span v-if="invalidKeys.includes('name')" class="text-red text-sm">
        {{ errors.name }}
      </span>
    </div>
    <div class="el-form-validation-margin mt-2">
      <label class="required form-label" for="surname">{{
        $t('product.subscribeToEventUpdatesModal.firstStep.form.surname.label')
      }}</label>
      <div class="position-relative">
        <input
          id="surname"
          v-model="form.surname"
          :placeholder="
            $t(
              'product.subscribeToEventUpdatesModal.firstStep.form.surname.placeholder'
            )
          "
          autocomplete="surname"
          class="w-full border border-gray-200 rounded-md p-2 bg-white mt-1"
          name="surname"
          @input="$emit('clearErrors', 'surname')"
        />
        <!-- <i
        class="bi bi-person-fill position-absolute top-50 start-0 ms-3 fs-4 translate-middle-y text-black-50"
      ></i> -->
      </div>
      <span v-if="invalidKeys.includes('surname')" class="text-red text-sm">
        {{ errors.surname }}
      </span>
    </div>
  </div>
  <div class="el-form-validation-margin mt-2">
    <label class="required form-label" for="email">{{
      $t('product.subscribeToEventUpdatesModal.firstStep.form.email.label')
    }}</label>
    <div class="position-relative">
      <input
        id="email"
        v-model="form.email"
        :placeholder="
          $t(
            'product.subscribeToEventUpdatesModal.firstStep.form.email.placeholder'
          )
        "
        autocomplete="email"
        type="email"
        class="w-full border border-gray-200 rounded-md p-2 bg-white mt-1"
        name="email"
        @input="$emit('clearErrors', 'email')"
      />

      <!-- <i
        class="bi bi-envelope-fill position-absolute top-50 start-0 ms-3 fs-4 translate-middle-y text-black-50"
      ></i> -->
    </div>
    <span v-if="invalidKeys.includes('email')" class="text-red text-sm">
      {{ errors.email }}
    </span>
  </div>
  <div class="row justify-content-between">
    <div
      class="el-form-validation-margin mt-2 col-5 d-flex justify-content-end flex-column"
    >
      <label class="required form-label" for="numberOfTickets">{{
        $t(
          'product.subscribeToEventUpdatesModal.firstStep.form.numberOfTickets.label'
        )
      }}</label>
      <div class="position-relative">
        <input
          id="numberOfTickets"
          v-model="form.numberOfTickets"
          :placeholder="
            $t(
              'product.subscribeToEventUpdatesModal.firstStep.form.numberOfTickets.placeholder'
            )
          "
          autocomplete="numberOfTickets"
          class="w-full border border-gray-200 rounded-md p-2 bg-white mt-1"
          name="numberOfTickets"
          type="number"
          :max="eventGetters.getMaxDateRequestTickets(computedEventData)"
          :min="eventGetters.getMinDateRequestTickets(computedEventData)"
          @input="$emit('clearErrors', 'numberOfTickets')"
        />
        <!-- <i
          class="bi bi-ticket-fill position-absolute top-50 start-0 ms-3 fs-4 translate-middle-y text-black-50"
        ></i> -->
      </div>
      <span
        v-if="invalidKeys.includes('numberOfTickets')"
        class="text-red text-sm"
      >
        {{ errors.numberOfTickets }}
      </span>
      <span
        v-if="
          !isDateRequestApplicable &&
          !isPrivateEventRequest &&
          !isEventRequestOpenedFromEventPage
        "
        class="text-red text-sm block"
      >
        {{
          $t(
            'product.subscribeToEventUpdatesModal.firstStep.form.numberOfTickets.errorText'
          )
        }}
      </span>
      <span
        v-if="
          isEventRequestOpenedFromEventPage &&
          !isDateRequestActive &&
          !isPrivateEventRequest
        "
      >
        {{
          $t(
            'product.subscribeToEventUpdatesModal.firstStep.form.numberOfTickets.helperText'
          )
        }}
      </span>
    </div>
    <div class="el-form-validation-margin mt-2 col-7">
      <label
        class="form-label"
        for="phone"
        :class="{ required: isPrivateEventRequest }"
        >{{
          $t('product.subscribeToEventUpdatesModal.firstStep.form.phone.label')
        }}</label
      >
      <div class="position-relative">
        <input
          id="phone"
          v-model="form.phone"
          :placeholder="
            $t(
              'product.subscribeToEventUpdatesModal.firstStep.form.phone.placeholder'
            )
          "
          autocomplete="phone"
          class="w-full border border-gray-200 rounded-md p-2 bg-white mt-1"
          name="phone"
          type="tel"
          @input="$emit('clearErrors', 'phone')"
        />
        <!-- <i
          class="bi bi-phone-fill position-absolute top-50 start-0 ms-3 fs-4 translate-middle-y text-black-50"
        ></i> -->
      </div>
      <span v-if="invalidKeys.includes('phone')" class="text-red text-sm">
        {{ errors.phone }}
      </span>
      <span v-else class="block mb-2 text-xs text-gray-400">
        {{
          $t(
            'product.subscribeToEventUpdatesModal.firstStep.form.phone.helperText'
          )
        }}
      </span>
    </div>
  </div>
</template>
<script lang="ts" setup>
interface Props {
  eventData: EventDescription
  isEventRequestOpenedFromEventPage: boolean
  isPrivateEventRequest: boolean
  errors: DateRequestErrors
  invalidKeys: DateRequestInvalidKeys
}
const props = withDefaults(defineProps<Props>(), {
  eventData: {},
  isEventRequestOpenedFromEventPage: false,
  isPrivateEventRequest: true,
  errors: {},
  invalidKeys: [],
})
const isDateRequestActive = eventGetters.isDateRequestActive(props.eventData)
const emit = defineEmits<{
  clearErrors: [id: string]
}>()
const form = defineModel()
const firstName = ref(null)
const focusFirstInput = () => {
  if (firstName.value) {
    firstName.value.focus()
  }
}
onMounted(focusFirstInput)
</script>
<style>
.required:after {
  content: '*';
  position: relative;
  font-size: inherit;
  @apply text-red;
  padding-left: 0.25rem;
  font-weight: 700;
}
</style>
