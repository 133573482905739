<script setup lang="ts">
export interface INavLinkItem {
  path: string
  text: string
  /* whether to show this nav item in  */
  showOnSub?: boolean
}

defineEmits(['navbarClose'])

withDefaults(
  defineProps<{
    isOpen: boolean
    navLinks: INavLinkItem[]
  }>(),
  { isOpen: false }
)

const fullLocalizedUrl = useFullLocalizedUrl()
const subdomain = useSubdomain()
</script>
<template>
  <nav
    id="navbar-sticky"
    class="fixed top-0 left-0 w-full h-full md:static md:h-fit bg-white md:block border-y"
    :class="[isOpen ? 'block' : 'hidden']"
  >
    <div class="max-w-screen-xl px-4 py-4 md:py-1 mx-auto h-full flex flex-col">
      <div>
        <span
          class="inline-block text-3xl font-thin rotate-45 mb-8 md:hidden"
          @click="$emit('navbarClose')"
          >+</span
        >
      </div>
      <div class="flex flex-col md:flex-row md:items-center md:justify-between">
        <ul
          class="flex flex-col md:flex-row font-medium mt-0 space-y-4 md:space-y-0 md:space-x-8 rtl:space-x-reverse text-sm"
        >
          <li
            v-for="item in navLinks"
            :key="item.path"
            class="border-b pb-4 md:border-none md:pb-0"
          >
            <NuxtLinkLocale
              class="text-gray-800 hover:underline text-base"
              :to="item.path"
              @click="$emit('navbarClose')"
              >{{ $t(item.text) }}</NuxtLinkLocale
            >
          </li>
        </ul>
        <TeamEventRequestButton v-if="!subdomain" class="hidden sm:block"
          >{{ $t('home.headerButtonTitle') }}
        </TeamEventRequestButton>
      </div>
      <LocaleSwitcher @navbar-close="$emit('navbarClose')" />
      <MinimalContactInfo />
    </div>
  </nav>
</template>
