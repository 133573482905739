<template>
  <div :class="twMerge('text-stone-900 underline', $attrs.class)">
    <template v-if="isExternal(link) || forceAnchor || openNewTab">
      <!-- @slot Custom markup for outside, after the anchor -->

      <a
        :href="withTrailingSlash(link || '')"
        :target="openNewTab ? '_blank' : '_self'"
        :itemprop="itemprop"
        :itemscope="itemscope"
        :itemtype="itemtype"
        :itemid="itemid"
      >
        <slot />
      </a>
      <slot name="after-anchor"></slot>
    </template>
    <template v-else>
      <nuxt-link v-if="link !== ''" :to="link" @click="$emit('click:link')">
        <slot />
      </nuxt-link>
      <a v-else href="" @click.prevent.stop="$emit('click:link')">
        <slot />
      </a>
    </template>
  </div>
</template>
<script>
import { twMerge } from 'tailwind-merge'
import { withTrailingSlash } from 'ufo'

export default {
  props: {
    /**
     * Page route
     */
    link: {
      type: [String, Object],
      default: '',
    },

    /**
     * Link Target
     */
    openNewTab: {
      type: Boolean,
      default: false,
    },

    /**
     * Force anchor tag
     */
    forceAnchor: {
      type: Boolean,
      default: false,
    },

    /**
     * SEO attrs
     */
    itemprop: {
      type: String,
      default: undefined,
    },
    itemscope: {
      type: Boolean,
      default: undefined,
    },

    itemtype: {
      type: String,
      default: undefined,
    },
    itemid: {
      type: String,
      default: undefined,
    },
  },
  emits: ['click:link'],

  setup() {
    const isExternal = (link) => {
      if (typeof link !== 'string') {
        return true
      }

      if (
        link.includes('http://') ||
        link.includes('https://') ||
        link.includes('mailto:')
      ) {
        return true
      }

      return false
    }

    return {
      isExternal,
      twMerge,
      withTrailingSlash,
    }
  },
}
</script>
<style lang="css">
@import '~/assets/css/link.css';
</style>
