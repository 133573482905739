<script setup lang="ts">
defineEmits('navbarClose')
const { locales } = useI18n()
const switchLocalePath = useSwitchLocalePath()
const localizedFullPath = useFullLocalizedUrl()
const route = useRoute()
const shouldShowLanguagePicker = computed(
  () => !route.name?.includes('magazine')
)
</script>

<template>
  <div class="mt-auto md:hidden">
    <div v-if="shouldShowLanguagePicker">
      <div
        class="border-b pb-4 mb-4 md:border-none md:pb-0 text-gray-800 hover:underline text-base"
      >
        {{ $t('general.language') }}
      </div>
      <NuxtLink
        v-for="locale in locales"
        :key="locale.code"
        class="mr-4"
        :to="switchLocalePath(locale.code)"
        @click="$emit('navbarClose')"
        >{{ locale.label }}</NuxtLink
      >
    </div>
  </div>
</template>

<style lang="postcss">
.router-link-active {
  @apply text-primary;
}
</style>
