<template>
  <div class="flex flex-col">
    <div
      class="complete-alert p-8 flex flex-col items-center justify-center bg-lime-200 rouunded-lg"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        class="bi bi-rocket-takeoff-fill"
        viewBox="0 0 16 16"
      >
        <path
          d="M12.17 9.53c2.307-2.592 3.278-4.684 3.641-6.218.21-.887.214-1.58.16-2.065a3.6 3.6 0 0 0-.108-.563 2 2 0 0 0-.078-.23V.453c-.073-.164-.168-.234-.352-.295a2 2 0 0 0-.16-.045 4 4 0 0 0-.57-.093c-.49-.044-1.19-.03-2.08.188-1.536.374-3.618 1.343-6.161 3.604l-2.4.238h-.006a2.55 2.55 0 0 0-1.524.734L.15 7.17a.512.512 0 0 0 .433.868l1.896-.271c.28-.04.592.013.955.132.232.076.437.16.655.248l.203.083c.196.816.66 1.58 1.275 2.195.613.614 1.376 1.08 2.191 1.277l.082.202c.089.218.173.424.249.657.118.363.172.676.132.956l-.271 1.9a.512.512 0 0 0 .867.433l2.382-2.386c.41-.41.668-.949.732-1.526zm.11-3.699c-.797.8-1.93.961-2.528.362-.598-.6-.436-1.733.361-2.532.798-.799 1.93-.96 2.528-.361s.437 1.732-.36 2.531Z"
        />
        <path
          d="M5.205 10.787a7.6 7.6 0 0 0 1.804 1.352c-1.118 1.007-4.929 2.028-5.054 1.903-.126-.127.737-4.189 1.839-5.18.346.69.837 1.35 1.411 1.925"
        />
      </svg>

      <div>
        <p>{{ $t('product.dateRequest.eventSuggestions.requestSent') }}</p>
      </div>
    </div>
    <h5 class="my-8">
      {{ $t('product.dateRequest.eventSuggestions.title') }}
    </h5>
    <div
      v-for="(item, i) in similarEvents.slice(0, 4)"
      :key="i"
      class="mb-2 mb-md-4"
      @click="toggleDateRequestSidebar(false)"
    >
      <NuxtLink
        class="kft-link--black"
        :to="localePath(algoliaEventGetters.fnGetLink(item, null, false))"
      >
        <div class="flex gap-4">
          <!--BEGIN::IMAGE WRAPPER  -->
          <div class="image-wrapper">
            <div class="kft-button--pure kft-product-card__link">
              <NuxtImg
                :alt="algoliaEventGetters.fnGetTitle(item)"
                :src="String(algoliaEventGetters.getPrincipalImage(item))"
                class="w-20 sm:w-32"
                fit="cover"
                sizes="xs:300"
              />
            </div>
          </div>
          <!-- END::IMAGE WRAPPER -->

          <div class="event-details">
            <!-- BEGIN::TITLE -->
            <div class="kft-button--pure kft-product-card__link">
              <span
                class="event-details__title"
                data-test-id="kft-product-card-title"
              >
                {{ algoliaEventGetters.fnGetTitle(item) }}
              </span>
            </div>
            <!-- END::TITLE -->
            <!-- BEGIN::QUICK ICONS -->
            <div>
              <div class="d-flex align-items-center">
                <span class="">
                  <i class="bi bi-clock-fill"></i>
                  {{
                    $t('product.card.duration', {
                      duration: fnGetFormattedDuration(
                        algoliaEventGetters.fnGetDuration(item),
                        defaultDurationString
                      ),
                    })
                  }}
                </span>
              </div>
            </div>
            <!-- END::QUICK ICONS -->
          </div>
        </div>
      </NuxtLink>
    </div>
    <Button
      v-if="Boolean(eventGetters.getMainCategorySlug(eventData))"
      class="btn btn-primary mt-4 w-100 d-flex align-items-center justify-content-center"
      @click.prevent="handleClickSeeMore"
    >
      <span>
        {{ $t('product.dateRequest.eventSuggestions.seeMoreButton') }}
      </span>
    </Button>
  </div>
</template>

<script setup lang="ts">
const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const eventData = useEventData()
const localePath = useLocalePath()
const { toggleDateRequestSidebar } = useUiState()

const emit = defineEmits(['onCloseDateRequest'])

const { data: similarEvents = ref([]) } = useFetchSimilarEvents(
  eventGetters.getPermalink(eventData.value),
  {
    lazy: true,
    server: false,
    transform: transformToAlgoliaEvent,
    default: () => [],
  }
)

const defaultDurationString = t('product.card.durationDefaultContent')

const handleClickSeeMore = () => {
  toggleDateRequestSidebar(false)
  emit('onCloseDateRequest')
  router.push({
    path: localePath('search'),
    query: {
      category: eventGetters.getMainCategory(eventData),
    },
  })
}
const fnGetFormattedDuration = (duration) => {
  const durationText = convertMinutesToHumanString(duration)

  if (durationText === '') {
    return t('product.card.durationDefaultContent')
  }

  return durationText
}
</script>

<style lang="postcss" scoped></style>
