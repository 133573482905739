<template>
  <Overlay :visible="isCartSidebarOpen" @click="closeCartSidebar" />
  <Drawer
    ref="cartSidebarRef"
    class="w-full sm:w-[600px] px-0 py-4 md:py-8 pb-4 flex flex-col h-[100%]"
    :is-open="isCartSidebarOpen"
    data-test-id="cart-sidebar__aside"
    @click:close="closeCartSidebar"
  >
    <template #header>
      <LazyCartHeader
        v-if="isCartSidebarOpen"
        class="px-4 md:px-8 sticky w-full top-0"
      />
    </template>

    <div class="flex flex-col justify-between flex-1 [&>*]:px-4 md:[&>*]:px-8">
      <LazyCartBody v-if="isCartSidebarOpen" class="mb-32" />
      <LazyCartFooter
        v-if="isCartSidebarOpen"
        class="border-t-gray-200 border-t fixed sm:sticky bottom-0 w-full bg-white z-10 pb-4 sm:pb-0"
      />
    </div>
  </Drawer>
</template>

<script lang="ts" setup>
import CartHeader from '~/components/cart/CartHeader.vue'

const cartSidebarRef = ref(null)

const { toggleCartSidebar, isCartSidebarOpen, closeCartSidebar } = useUiState()

defineExpose({ toggleCartSidebar })
</script>
<style>
body.prevent-scroll {
  overflow: hidden;
}
</style>
