<template>
  <div
    class="flex h-full self-center flex-col justify-end pb-[1rem] md:pb-[10rem]"
  >
    <div class="flex w-[290px] flex-wrap gap-5">
      <div class="text-h4 text-center leading-7">
        {{ $t('product.dateRequest.generalSuccessMessage') }}
      </div>
      <Button class="w-full font-semibold" @click="isShareModalOpen = true">
        {{ $t('product.dateRequest.generalSuccessShareEventButton') }}
      </Button>

      <ShareDialog
        :is-share-modal-open="isShareModalOpen"
        :page-title="pageTitle"
        :link="pageLink"
        @close="toggleShareModalOpen"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const isShareModalOpen = ref(false)

const eventData = useEventData()
const route = useRoute()

const event = computed(
  () => route.name?.split('___')[0] === 'e-eventPermalink' && eventData.value
)
const { t } = useI18n()

const pageTitle = computed(() =>
  event.value ? eventGetters.getTitle(event.value) : t('general.titles.index')
)

const fullLocalizedUrl = useFullLocalizedUrl()

const pageLink = computed(() =>
  event.value
    ? fullLocalizedUrl(
        '/' +
          encodeURIComponent(
            `${route.path}&utm_source=konfetti&utm_medium=share_event&utm_campaign=facebook`
          )
      )
    : process.client && fullLocalizedUrl('/')
)

const toggleShareModalOpen = () => {
  isShareModalOpen.value = !isShareModalOpen.value
}
</script>
