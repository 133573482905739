<template>
  <transition
    enter-active-class="duration-300 ease-out"
    enter-from-class="transform opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-200 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="transform opacity-0"
  >
    <div
      v-if="props.visible"
      ref="overlay"
      class="bg-gray-900/50 fixed inset-0 z-30"
    ></div>
  </transition>
</template>
<script lang="ts" setup>
const props = defineProps<{
  visible: boolean
}>()
</script>
