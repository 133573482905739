<template>
  <div class="mt-8 md:hidden">
    <div
      class="border-b pb-4 mb-4 md:border-none md:pb-0 text-gray-800 hover:underline text-base"
    >
      {{ $t('general.contactUs') }}
    </div>
    <div class="pb-4">
      {{ $t('general.contactPhone') }}
    </div>
    <div class="pb-4">
      {{ $t('general.contactEmail') }}
    </div>
  </div>
</template>
