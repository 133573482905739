<script lang="ts" setup>
import IconClose from '~/components/icons/IconClose'
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  dataTestId: {
    type: String,
    default: '',
  },
})
</script>

<template>
  <!-- drawer component -->
  <div
    id="drawer"
    class="shadow-lg overflow-hidden fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto transition-transform duration-300 bg-white w-80"
    :class="[
      {
        'translate-x-full': !props.isOpen,
        'translate-none': props.isOpen,
      },
    ]"
    tabindex="-1"
    aria-labelledby="drawer-right-label"
  >
    <div class="mb-4">
      <slot name="header"> </slot>
      <button
        type="button"
        aria-controls="drawer"
        aria-label="Close"
        :data-test-id="`${dataTestId}-close-button`"
        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 mt-1 inline-flex items-center justify-center"
        @click="$emit('click:close')"
      >
        <IconClose />
        <span class="sr-only">Close menu</span>
      </button>
    </div>

    <slot></slot>
  </div>
</template>
