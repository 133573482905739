import { jwtDecode } from 'jwt-decode'

export const useAuth = () => {
  const user = useLocalStorage('user', undefined)
  const refreshToken = useLocalStorage('refresh_token', undefined)
  const accessToken = useLocalStorage('access_token', undefined)

  const isTokenExpired = (token: string) => {
    const decoded = jwtDecode(token)

    if (!decoded.exp) return true

    return decoded.exp * 1000 < Date.now()
  }

  const isAuthenticated = computed(
    () =>
      !!user.value &&
      !!refreshToken.value &&
      !!accessToken.value &&
      !isTokenExpired(accessToken.value)
  )

  const logOut = () => {
    user.value = undefined
    refreshToken.value = undefined
    accessToken.value = undefined
  }

  return {
    isAuthenticated,
    logOut,
  }
}
